import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Row, Table } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const CSPVPBan: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>PVP Ban System</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/category_bans.jpg"
            alt="PVP Ban System"
          />
        </div>
        <div className="page-details">
          <h1>PVP Ban System</h1>
          <h2>CounterSide Ranked Gauntlet Bans & Ups System Mechanics</h2>
          <p>
            Last updated: <strong>08/04/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297253"></div>
      <div className="page-content">
        <SectionHeader title="Introduction" />
        <StaticImage
          src="../../../images/counterside/generic/pvp_ban.jpg"
          alt="Raid"
        />
        <p>
          <strong>Evelyn is not having a good time</strong>.
        </p>
        <h5>About the guide</h5>
        <p>
          The purpose of this guide is to briefly cover the rules governing
          Ranked Gauntlet’s Bans & Ups system. The Bans & Ups system is what
          makes PVP as enjoyable as it is, but the inner workings of this system
          are surprisingly unintuitive. It is very easy to misinterpret the
          weekly Bans & Ups rotations, especially since the information is not
          disclosed anywhere in-game.
        </p>
        <p>
          This information has been available on the official Discord community
          for over a year, but every single week the cast bans always show the
          same issues so now we’re putting it on Prydwen.
        </p>
        <SectionHeader title="What is the Bans & Ups system?" />
        <p>
          To introduce the system, Ranked Gauntlet, one of CounterSide’s PVP
          game modes, uses an automated ban system to adjust down overperforming
          characters and rotate the pool of playable characters every week.{' '}
        </p>
        <p>
          The system applies ban levels depending on how prevalent characters
          were in PVP during the weeks prior. Banned characters receive heavy
          debuffs that increase in severity as the ban level increases. Ban
          levels will drop at a rate of 1 per week if the character with the
          penalty is not selected again. A table with the full list of ban
          effects, as well as the values for subsequent ban levels, has been
          provided below:
        </p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Debuff</th>
              <th>Ban 1</th>
              <th>Ban 2</th>
              <th>Ban 3</th>
              <th>Ban 4</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>Dec. DMG</strong>
              </td>
              <td>
                <strong className="red">-10%</strong>
              </td>
              <td>
                <strong className="red">-20%</strong>
              </td>
              <td>
                <strong className="red">-30%</strong>
              </td>
              <td>
                <strong className="red">-40%</strong>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Inc. DMG Taken</strong>
              </td>
              <td>
                <strong className="red">-10%</strong>
              </td>
              <td>
                <strong className="red">-20%</strong>
              </td>
              <td>
                <strong className="red">-30%</strong>
              </td>
              <td>
                <strong className="red">-40%</strong>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Dec. ASPD</strong>
              </td>
              <td>
                <strong className="red">-20%</strong>
              </td>
              <td>
                <strong className="red">-40%</strong>
              </td>
              <td>
                <strong className="red">-60%</strong>
              </td>
              <td>
                <strong className="red">-80%</strong>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Dec. ASPD (Fury)</strong>
              </td>
              <td>
                <strong className="red">-40%</strong>
              </td>
              <td>
                <strong className="red">-80%</strong>
              </td>
              <td>
                <strong className="red">-120%</strong>
              </td>
              <td>
                <strong className="red">-160%</strong>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Dec. Skill Haste</strong>
              </td>
              <td>
                <strong className="red">-10%</strong>
              </td>
              <td>
                <strong className="red">-20%</strong>
              </td>
              <td>
                <strong className="red">-30%</strong>
              </td>
              <td>
                <strong className="red">-40%</strong>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Outgoing Healing Rdc.</strong>
              </td>
              <td>
                <strong className="red">-40%</strong>
              </td>
              <td>
                <strong className="red">-80%</strong>
              </td>
              <td>
                <strong className="red">-120%</strong>
              </td>
              <td>
                <strong className="red">-160%</strong>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Dec. Alt. Reactor Level</strong>
              </td>
              <td>
                <strong className="red">-1</strong>
              </td>
              <td>
                <strong className="red">-2</strong>
              </td>
              <td>
                <strong className="red">-3</strong>
              </td>
              <td>
                <strong className="red">-4</strong>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Other</strong>
              </td>
              <td colSpan={4}>Leader cost discount not applied</td>
            </tr>
            <tr>
              <td>
                <strong>Other (Rearms only)</strong>
              </td>
              <td colSpan={4}>Leader Skill active effect not applied.</td>
            </tr>
          </tbody>
        </Table>
        <p>The systems that determine ban level are:</p>
        <ul>
          <li>
            <strong>Rotation BAN</strong>: Rotation bans are fully automated and
            apply ban levels depending on total character wins over a span of
            two weeks.
          </li>
          <li>
            <strong>Casting BAN</strong>: Casting bans apply ban levels
            democratically through player votes. If a character is
            simultaneously rotation banned and cast banned, only one applies.
            The cast ban is invalidated.
          </li>
        </ul>
        <SectionHeader title="How does the Bans & Ups system work?" />
        <p>
          Credits to MagosLibrorum for compiling and translating the information
          from the old KR Nexon forums. See the original post{' '}
          <a
            href="https://discord.com/channels/952803521424670740/952829382379061290/1087020051401551913 "
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{' '}
          (it's the official CS Discord).
        </p>
        <h5>Rotation BAN</h5>
        <ol>
          <li>
            Players are divided into 2 groups, <strong>TOP</strong> (the top 300
            players) and
            <strong>REST</strong> (the rest).
          </li>
          <li>
            Characters/ ships with the highest number of wins will be banned.
          </li>
          <ul>
            <li>
              The numbers of wins from the <strong>LAST TWO WEEKS</strong> are
              counted.
            </li>
            <li>
              Only the numbers of wins are used; win rate and pick rate are
              irrelevant.{' '}
            </li>
          </ul>
          <li>Character rotation bans are generated as follows:</li>
          <ul>
            <li>
              For group <strong>TOP</strong>, for every 30 characters in the
              Collection, one ban is generated.{' '}
            </li>
            <li>
              For group <strong>REST</strong>, for every 40 characters in the
              Collection, one ban is generated.
            </li>
          </ul>
          <li>Ship rotation bans are generated as follows:</li>
          <ul>
            <li>
              For group <strong>TOP</strong>, for every 10 ships in the
              Collection, one ban is generated.
            </li>
          </ul>
          <li>Prior week UPs are not eligible for ban consideration.</li>
        </ol>
        <h5>Casting BAN</h5>
        <ol>
          <li>Characters/ ships with the highest votes will be banned.</li>
          <ul>
            <li>
              Votes from the <strong>LAST TWO WEEKS</strong> are counted.{' '}
            </li>
            <li>
              Only votes from group <strong>TOP</strong> are valid.{' '}
            </li>
            <li>Characters/ ships at ban level 2 are removed from the poll.</li>
          </ul>
          <li>Character cast bans are generated as follows:</li>
          <ul>
            <li>
              For every 65 characters in the Collection, one ban is generated.
            </li>
          </ul>
          <li>Ship cast bans are generated as follows:</li>
          <ul>
            <li>For every 20 ships in the Collection, one ban is generated.</li>
          </ul>
          <li>The highest number of Rotation/ Casting ban is applied.</li>
          <li>
            If a unit is picked for UP, the votes they get will be invalidated.
            The unit with the next highest votes will be banned instead.
          </li>
        </ol>
        <h5>UP</h5>
        <ol>
          <li>
            To be eligible for UP, characters must fulfill the following
            requirements:
          </li>
          <ul>
            <li>Be of ASSR/ SSR/ SR rarity.</li>
            <li>Have had more than one win in the LAST TWO WEEKS.</li>
            <li>Not receiving an increase in ban level.</li>
            <li>
              Their number of wins is in the lower 50% of all ASSR/ SSR/ SR
              characters.
            </li>
          </ul>
          <li>Character UPs are generated as follows:</li>
          <ul>
            <li>
              4 units are picked randomly from all that meet the conditions
              above.
            </li>
          </ul>
        </ol>
        <SectionHeader title="Key Takeaways" />
        <p>
          <strong>The entire system operates on a two week basis</strong>: The
          Bans & Ups system takes information from two weeks. Current week bans
          may not reflect the prior week’s PVP activity. This is the reason why
          Ban 2 characters can jump to Ban 3/ Ban 4 even when no one plays them
          at Ban 2.
        </p>
        <p>
          <strong>There is no such thing as two week ban immunity</strong>: A
          frequently observed phenomenon is that new characters don’t get banned
          in their first two weeks (including their debut week), regardless of
          how overpowered they are. This is because it is incredibly difficult
          to accumulate enough wins over half a week to compete with other
          characters with total wins over two weeks.
        </p>
        <p>
          <strong>Rotation BAN and Casting BAN does not stack</strong>: If a
          character/ ship is simultaneously rotation banned and cast banned,
          only one ban will apply. Players are heavily encouraged to use their
          cast bans to influence the subsequent week’s RTA meta.{' '}
        </p>
        <p>
          <strong>
            Votes from players ranked lower than 300 are not counted
          </strong>
          : If you want to make your voice heard, climb! For players looking to
          get into PVP, check out the Intro to RTA guide:{' '}
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Intro to RTA concepts"
            link="/counter-side/guides/rta-concepts"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_pvphistory.png"
                alt="Siege Playbook"
              />
            }
          />
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297253"></div>
    </DashboardLayout>
  );
};

export default CSPVPBan;

export const Head: React.FC = () => (
  <Seo
    title="PVP Ban System | Counter Side | Prydwen Institute"
    description="CounterSide Ranked Gauntlet Bans & Ups System Mechanics"
  />
);
